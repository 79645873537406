<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Curbing Losses In The Food Value Chain In Africa
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <div>
          <img src="../../assets/images/image 11 (3).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
          <!-- <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class="body-1 font-weight-bold">Date: April 1, 2019</p>
          </span> -->
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Curbing Losses In The Food Value Chain In Africa
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: April 29, 2019</p>
            </span>
          </div>
          <p class="pb-2">Originally from <span>techcabal</span></p>
          <p>
            Despite infrastructure hurdles, companies in Africa are taking the
            innovation route to reduce losses in the food value chain. Cold
            chain facilities play a prominent role in these efforts.
            <br /><br />
            An estimated 1 in every 4 people in sub-Saharan Africa is
            malnourished. Yet, a third of the food produced for human
            consumption every year gets lost or wasted, and forty percent of
            these losses happen at post-harvest and processing levels in Africa.
            The losses can be traced back to challenges with harvesting
            techniques, and storage and cooling facilities. <br /><br />
            According to the Food and Agricultural Organisation of the United
            Nations (FAO), “the lack of reliable and adequate cold chain
            facilities in sub-Saharan Africa is one of the main causes of losses
            of perishable products, which are estimated to be about 25-30
            percent for animal products and 40-50 percent for roots, tubers,
            fruits and vegetables.”
          </p>
          <!-- <p class="font-italic">
            A cold chain or cool chain is a temperature-controlled supply chain.
            An unbroken cold chain is an uninterrupted series of refrigerated
            production, storage and distribution activities, along with
            associated equipment and logistics, which maintain a desired
            low-temperature range.
          </p>
          <p>
            In healthcare, cold chain technology is required in the preservation
            and storage of healthcare products, medications and the
            dissemination of vaccines. Unfortunately 19.4 million infants do not
            receive routine immunization due to inadequate cold chain facility
            during transportation and storage. About 1.5 million annual deaths
            could have been prevented if there were adequate cold chain storage
            facilities.
          </p> -->
          <p class="body-1 font-weight-bold">
            Per capita food losses and waste (kg/year)
          </p>

          <img
            src="../../assets/images/image 12 (1).png"
            alt=""
            width="100%"
            class="py-8"
          />

          <p>
            Cold chain solutions provide a temperature controlled supply chain,
            thereby preserving food during the time between harvest and
            consumption. FAO identified access to energy, shortage of qualified
            staff and spare parts, poor logistics, weak organisation and
            implementation of controls for compliance with standards, and
            relatively low traded volumes as challenges for the development of
            cold chain technology on the continent. <br /><br />
            However, there are significant cold chain solutions in Africa
            already. About 10,000 rural farmers in Kenya cool and ripen bananas
            and other farm produce with InspiraFarms-developed cold storage
            units at a Twiga Foods warehouse in Nairobi. The units use 70
            percent less energy than traditional refrigeration systems and have
            up to two days of thermal backup capacity if there’s a power outage.
            <br /><br />
            In Rwanda, with support from the World Bank, the government
            installed 10 InspiraFarms solar-powered cooling units in rural areas
            that have no electricity or refrigeration. The units are expected to
            serve about 100,000 smallholder farmers. <br /><br />
            Efforts to use cold chain solutions in Nigeria, to address food
            insecurity issues, have also been made. APM Terminals, an
            international container terminal operating company, organised a
            trial shipment of 18 tonnes of fresh tomatoes from Kaduna to Lagos,
            a 621-mile trip. The tomatoes were delivered, however, the company
            did not make additional shipments, citing poorly established retail
            networks and road congestion as challenges. <br /><br />
            ColdHubs, a company founded by Nnaemeka Ikegwuonu, a Nigerian
            entrepreneur, develops solar-powered refrigeration units. Each
            ColdHub can fit approximately three tonnes of perishable food, and
            ColdHubs operates a simple pay-as-you-store model, with farmers and
            retailers paying less than US$0.50 to store one crate for one day.
            ColdHubs had five units in Nigeria by October 2018 operating at full
            capacity, with plans to set up at least twenty more in 2019, and
            expand to other African countries. <br /><br />
            Oghenetega Iortim, the CEO of Gricd Frij, a cold chain solution in
            Nigeria developed to prevent post-harvest losses in agriculture and
            provide storage and transportation of medical supplies, says his
            team is working on scaling their current solution–luggage-sized cold
            chain boxes for transporting medical supplies–into larger, more
            economical models for farmers.<br /><br />
            Oghenetega mentions that transporting vaccines also has an effect on
            food losses, as animal vaccines are sometimes affected by breaks in
            the cold chain. “There are a lot of disease outbreaks that occur in
            cattle and poultry in Nigeria, for which vaccines exist. However,
            these diseases still occur in vaccinated animals because some of the
            vaccines have lost their potency as a result of breaks in
            temperature control during transportation.” <br />
            There are alternatives to cold chain that others are exploring
            too.<br /><br />
            Coating+, a company formed by a group of biochemistry students at
            the University of Ilorin, Nigeria, employed biotechnology to create
            a transparent gelatinous coating that is sprayed onto fresh fruit
            and vegetables to prolong their shelf life. It’s made using a
            combination of chitosan, a sugar extracted from shrimp shells, along
            with soy protein and micronutrients. Coating+ won a US$10,000 prize
            in 2018, with plans to conclude regulatory approval and increase
            production capabilities. <br /><br />
            The Rockefeller Foundation also launched YieldWise in 2016, a US
            $130 million initiative to demonstrate how the world can halve food
            loss by 2030. They are currently focused on four value chains in
            three countries: maize in Tanzania, cassava and tomatoes in Nigeria,
            and mangoes in Kenya. The initiative is investing in technology
            innovations like mobile processing and solar-drying to extend the
            shelf life of the crops. Thus far, YieldWise boasts 20-30 percent
            loss reduction as “early results” of their efforts. <br /><br />
            Progress is being made to eliminate waste, with the creative
            entrepreneurs and active investments in the space, but more still
            needs to be done.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{hover}">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
import img2 from '../../assets/images/Rectangle 26.png';
import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/image (7).png';
import image5 from '../../assets/images/image (10).png';
import image6 from '../../assets/images/image (9).png';
import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        date: '07 March, 2019',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
      {
        img: img2,
        content: 'Technology Firm Introduces Healthcare Product',
        date: '01 April, 2019',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
      {
        img: img3,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        date: '29 April, 2019',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
    ],
    lastCard: [
      {
        img: image4,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        title: 'Agriculture',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
      {
        img: image6,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        title: 'Logistics',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
      {
        img: image5,
        content: 'Technology Firm Introduces Healthcare Product',
        title: 'Healthcare',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: 'Curbing Losses In The Food Value Chain In Africa',
                item:
                  'https://gricd.com/blog/curbing-losses-in-the-food-value-chain-in-africa',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
</style>
